/* eslint-disable */
import React, { useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Tabs, { TabPane } from "rc-tabs"
import TabContent from "rc-tabs/lib/TabContent"
import ScrollableInkTabBar from "rc-tabs/lib/ScrollableInkTabBar"
import "rc-tabs/assets/index.css"
import Text from "../../../components/Text"
import Heading from "../../../components/Heading"
import Container from "../../../components/Container"
import Box from "../../../components/Box"
import Button from "../../../components/Button"
import Image from "../../../components/Image"
import three from "../../../assets/images/about/three.png"
import Card from "../../../components/Card"
import Fade from "react-reveal/Fade"

import { SectionWrapper, TextWrapper, SectionImage } from "./style"

const TestimonialSection = ({
  nameStyle,
  row,
  titleStyle,
  number,
  wrapper,
  designationStyle,
  sectionHeader,
  sectionTitle,
  threeImg,
  desiStyle,
  col1,
  imageWrapper,
  deviceSize2,
  deviceSize1,
  screenWeb1,
  screenWeb2,
  screenWeb3,
  screenMobileOne,
  screenMobileTwo,
  screenMobileThree,
  imgWrapper2,
  imgWrapper1,
  cnt,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      dataJson {
        WEB_MOBILE {
          title
          identify
          image {
            publicURL
          }
          data {
            phone {
              publicURL
            }
            designation
            id
            num
            name
          }
        }
      }
    }
  `)
  const [show1, setShow1] = useState(true)
  const [show2, setShow2] = useState(false)
  const [show3, setShow3] = useState(false)
  const [show4, setShow4] = useState(true)
  const [show5, setShow5] = useState(false)
  const [show6, setShow6] = useState(false)

  const screenCall1 = () => {
    setShow1(true)
    setShow2(false)
    setShow3(false)
  }
  const screenCall2 = () => {
    setShow1(false)
    setShow2(true)
    setShow3(false)
  }
  const screenCall3 = () => {
    setShow1(false)
    setShow2(false)
    setShow3(true)
  }
  const screenCall4 = () => {
    setShow4(true)
    setShow5(false)
    setShow6(false)
  }
  const screenCall5 = () => {
    setShow4(false)
    setShow5(true)
    setShow6(false)
  }
  const screenCall6 = () => {
    setShow4(false)
    setShow5(false)
    setShow6(true)
  }
  return (
    <Container width="30%">
      <SectionWrapper>
        <Box {...wrapper}>
          <Image src={three} alt="three" {...threeImg} />
          <Box>
            <Text
              content={"einfache Schritte für Ihren Start!"}
              {...designationStyle}
            />
          </Box>
        </Box>

        <Tabs
          renderTabBar={() => <ScrollableInkTabBar />}
          renderTabContent={() => <TabContent animated={false} />}
          className="update-screen-tab"
        >
          {Data.dataJson.WEB_MOBILE.map((item, index) => (
            <TabPane
              forceRender={true}
              tab={<Text content={item.title} {...titleStyle} />}
              key={index + 1}
            >
              <Box {...col1}>
                <SectionImage>
                  <Card className="dashboardWrapper" {...imageWrapper}>
                    <Box
                      {...(item.title === "Mobile"
                        ? { ...imgWrapper1 }
                        : { ...imgWrapper2 })}
                    >
                      {item.data.map((item, index) => (
                        <Fade key={index + 1}>
                          <Image
                            src={
                              item.id === 1
                                ? (show1 ? item.phone.publicURL :'false')
                                : item.id === 2
                                ?( show2 ? item.phone.publicURL :'false')
                                : item.id === 3
                                ? (show3 ? item.phone.publicURL :'false')
                                : item.id === 4
                                ? (show4 ? item.phone.publicURL :'false')
                                : item.id === 5
                                ? (show5 ? item.phone.publicURL :'false')
                                : item.id === 6
                                ? (show6 ? item.phone.publicURL :'false')
                                : "false"
                            }
                            {...(item.id === 1
                              ? { ...screenMobileOne }
                              : item.id === 2
                              ? { ...screenMobileTwo }
                              : item.id === 3
                              ? { ...screenMobileThree }
                              : item.id === 4
                              ? { ...screenWeb1 }
                              : item.id === 5
                              ? { ...screenWeb2 }
                              : item.id === 6
                              ? { ...screenWeb3 }
                              : "false")}
                            key={index + 1}
                            alt=''
                          />
                        </Fade>
                      ))}
                    </Box>

                    <Image
                      src={item.image.publicURL}
                      {...(item.title === "Mobile"
                        ? { ...deviceSize1 }
                        : { ...deviceSize2 })}
                      alt="VisitorDashboard1"
                      className="png"
                    />
                  </Card>
                </SectionImage>
              </Box>

              <Box {...row} key={`feature-item-${index}`}>
                <TextWrapper>
                  {item.data.map((item, index) => (
                    <Box {...row} key={index}>
                      <Button
                        onClick={
                          item.id === 1
                            ? screenCall1
                            : item.id === 2
                            ? screenCall2
                            : item.id === 3
                            ? screenCall3
                            : item.id === 4
                            ? screenCall4
                            : item.id === 5
                            ? screenCall5
                            : item.id === 6
                            ? screenCall6
                            : ""
                        }
                        variant="webmobileIcon"
                        title={item.num}
                        {...number}
                      />
                      <Text
                        content={item.name}
                        {...nameStyle}
                        className={
                          item.id === 2 ? "text-ex-bold" : "text-m-bold"
                        }
                      />
                      <Heading
                        content={item.designation}
                        {...desiStyle}
                        className="textSizes"
                      />
                    </Box>
                  ))}
                </TextWrapper>
              </Box>
            </TabPane>
          ))}
        </Tabs>
      </SectionWrapper>
    </Container>
  )
}

// Footer style props
TestimonialSection.propTypes = {
  logoStyle: PropTypes.object,
  wrapper: PropTypes.object,
  nameStyle: PropTypes.object,
  designationStyle: PropTypes.object,
  row: PropTypes.object,
  titleStyle: PropTypes.object,
  number: PropTypes.object,
  desiStyle: PropTypes.object,
}
TestimonialSection.defaultProps = {
  logoStyle: {
    width: "128px",
    m: "0 auto",
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    mt: "-20%",
    mb: ["0%", "10%", "10%", "15%", "15%", "15%"],
    ml: "0%",
  },
  nameStyle: {
    // as: "h3",
    color: "#98e044",
    fontWeight: "350",
    fontSize: ["14px", "18px"],
    ml: ["-5%", "0%"],
    lineHeight: ["23px", "33px"],
    mb: ["5%", "auto"],
    mr: "0%",
  },
  titleStyle: {
    color: "#98e044",
    fontSize: ["30px", "36px", "36px", "36px", "36px", "36px"],
    pl: ["17px", "auto"],
    pr: ["17px", "auto"],
  },
  designationStyle: {
    width: ["275px", "480px", "556px", "556px", "556px", "556px"],
    height: "145px",
    color: "#797979",
    fontWeight: "600",
    fontSize: ["38px", "55px", "66px", "66px", "60px", "66px"],
    lineHeight: "1.24",
    lettreSpicing: "-1.65px",
    textAlign: "left",
    ml: "5%",
  },
  desiStyle: {
    display: "flex",
    width: ["250px", "557px", "557px", "557px", "557px", "557px"],
    height: ["190px", "160px", "143px", "143px", "143px", "143px"],
    color: "#9fa3a7",
    fontWeight: "350",
    fontSize: ["12.5px", "14.5px", "14.5px", "14.5px", "14.5px", "16.5px"],
    lineHeight: "1.57",
    textAlign: "left",
    ml: ["7%", "14%", "14%", "14%", "14%", "14%"],
    mb: ["-10%", "0%", "-5%", "-2%", "-2%", "-2%"],
    mt: "5%",
  },
  row: {
    flexBox: true,
    flexWrap: "wrap",
    ml: "-15px",
    mr: "-15px",
    pb: "0px",
  },

  number: {
    color: "#98e044",
    fontWeight: "400",
    fontSize: "16px",
  },

  threeImg: {
    width: ["100px", "72px", "107px", "107px", "90px", "107px"],
    height: ["140px", "127px", "152px", "152px", "144px", "152px"],
  },

  col1: {
    width: ["0%", "0%", "0%", "100%", "100%", "100%"],
    mb: ["0%", "0%", "0%", "0%", "-12%", "0"],
    height: "100px",
  },
  imageWrapper: {
    mt: "14%",
    boxShadow: "none",
    width: "200%",
  },

  screenMobileOne: {
    mt: ["0%", "0%", "0%", "-2%", "-75%", "20%"],
    width: "35%",
    height: "100%",
    ml: "-15%",
  },
  screenMobileTwo: {
    mt: ["0%", "0%", "0%", "-2%", "0%", "20%"],
    width: "35%",
    height: "100%",
    ml: "-15%",
  },
  screenMobileThree: {
    mt: ["0%", "0%", "0%", "-2%", "0%", "20%"],
    width: "35%",
    height: "100%",
    ml: "-15%",
  },
  screenWeb1: {
    mt: ["0%", "0%", "0%", "-2%", "-75%", "20%"],
    width: "66%",
    height: "100%",
    ml: "-33%",
  },
  screenWeb2: {
    mt: ["0%", "0%", "0%", "-2%", "0%", "20%"],
    width: "66%",
    height: "100%",
    ml: "-35%",
  },
  screenWeb3: {
    mt: ["0%", "0%", "0%", "-2%", "0%", "20%"],
    width: "66%",
    height: "100%",
    ml: "-35%",
  },
  deviceSize1: {
    mt: ["0%", "0%", "0%", "-2%", "-71%", "20%"],
    width: "63%",
    height: "100%",
    ml: "9%",
  },

  deviceSize2: {
    mt: ["0%", "0%", "0%", "-2%", "-44%", "20%"],
    width: "100%",
    height: "50%",
    ml: "-20%",
  },
  imgWrapper1: {
    mt: ["0%", "0%", "0%", "-2%", "110%", "20%"],
    width: "88%",
    height: "100%",
    ml: "29.5%",
  },
  imgWrapper2: {
    mt: ["0%", "0%", "0%", "-2%", "78%", "20%"],
    width: "97%",
    height: "100%",
    ml: "29.5%",
  },
  cnt: {
    width: ["0%", "30%"],
  },
}

export default TestimonialSection
