import styled from "styled-components"

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
  margin-left: 120px;
  margin-top: 15%;
  @media (max-width: 1250px) and (min-width: 200px) {
    flex-direction: column;
    margin-left: 0px;
  }
  .flex-text1 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .flex-text2 {
    margin-left: -31%;
    font-size:40px;
    display: flex;
    justify-content: flex-end;
    width: 71%;
    height:50%;
    font-weight:700;
    color:#797979
  }
  .flex-text3 {
    font-size:40px;
    height:50%;
    margin-top:-2%;

    margin-left: -30%;
    display: flex;
    justify-content: flex-end;
    width: 70%;
    color: #98e044;
    font-weight:700
  }
  .fex-width {
    width: 50%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  .imgSize {
    display: flex;
    margin-top: 40px;
    width: 586px;
    @media (max-width: 1250px) and (min-width: 991px) {
      width: 100%;
      height: 100%;
      margin: 10%;
    }
    @media (max-width: 991px) and (min-width: 200px) {
      display: none;
    }
  }
  .flex-box {
    flex-direction: column;
  }
  .hideShow {
    @media (max-width: 1250px) {
      display: none;
    }
  }
`
