import styled from "styled-components"
export const SlideWrapper = styled.div`
  margin-top: -6%;
  margin-bottom: 20%;
  margin-left: -6px;
  width: 250%;
  height: 100%;
  @media (max-width: 425px) {
    margin-bottom: -40%;
  }
  .imgSlider {
    @media (max-width: 991px) {
      margin-left: 18%;
    }

    @media (max-width: 767px) {
      display: none;
    }
  }

  .glide__slide {
    height: 1000px;
    @media (max-width: 768px) {
      height: 950px;
    }
    @media (max-width: 675px) {
      height: 900px;
    }
    @media (max-width: 575px) {
      height: 625px;
    }
  }
  .glide__bullets {
    display: flex;

    margin-left: 100px;
    margin-top: -7%;
    margin-left: 6%;
    text-align: left;
    @media (max-width: 1440px) {
      margin-left: 7%;
    }
    @media (max-width: 1024px) {
      margin-left: 7%;
    }
    @media (max-width: 575px) {
      display: none;
    }
    @media (max-width: 768px) {
      margin-top: -15%;
      margin-bottom: 20%;
    }
    @media (max-width: 425px) {
      margin-top: -15%;
    }

    .glide__bullet {
      width: 10px;
      height: 10px;
      background: #cbcbcb;
      margin: 4px;
      transition: 0.15s ease-in-out;

      @media (max-width: 575px) {
        width: 7px;
        height: 7px;
      }
      &:hover {
        background: #98e044;
      }
      &.glide__bullet--active {
        background: #98e044;
      }
    }
  }
  .jNvNjH {
    @media (max-width: 375px) {
      margin-bottom: 0;
    }
  }
  .flex-img-slider {
    @media (max-width: 1440px) {
      margin-top: 20%;
    }
    @media (max-width: 1024px) {
      margin-top: 10%;
    }
  }
  .flex-img-slider-pos {
    @media (max-width: 768px) {
      max-width: 70%;
      margin-left: 49%;
    }
  }
  .btn-register {
    font-family: sans-serif;
    @media (max-width: 1440px) {
      width: 400px;
      height: 105px;
    }
    @media (min-width: 769px) and (max-width: 1024px) {
      width: 335px;
      height: 105px;
    }
    @media (max-width: 768px) {
      width: 300px;
      height: 90px;
    }
    @media (max-width: 425px) {
      height: 70px;
    }
  }
`
export const TestimonialItem = styled.div`
  position: relative;
  display: block;
  background: transparent;
  font-family: Raleway;
  padding: 23px;
  @media (max-width: 768px) {
    width: 355px;
  }
  @media (max-width: 575px) {
    width: 300px;
  }
  @media (max-width: 425px) {
    width: 300px;
  }
  @media (max-width: 375px) {
    width: 280px;
  }
  @media (max-width: 320px) {
    width: 250px;
  }
  @media (max-width: 575px) {
    padding: 0px;
  }
`
export const SliderBox = styled.div`
  .jNvNjH {
    @media (max-width: 375px) {
      margin-bottom: 0px;
    }
  }
`
export default SlideWrapper
