import styled from "styled-components"

const SectionWrapper = styled.section`
  left: 50%;

  padding: 10px 0 0 0;
  @media (max-width: 1382px) {
    padding: 10% 0 0 0;
  }
  @media (max-width: 1220px) {
    left: 0%;
    padding: 55% 0 0 0;
  }
  @media (max-width: 1024px) {
    padding: 19% 0 0 0;
  }
  @media (max-width: 990px) {
    padding: 60% 0 0 0;
  }

  @media (max-width: 768px) {
    padding: 20px 0 0 0;
  }
  @media (max-width: 575px) {
    padding: -50px 0 0 0;
  }

  @keyframes ScaleInUp {
    from {
      opacity: 0;
      transform: translateY(30px) scale(0.97);
    }
    to {
      opacity: 1;
      transform: translateY(0) scale(1);
    }
  }
  .showHide {
    @media (max-width: 768px) {
      display: none;
    }
  }
  
  }
  .update-screen-tab {
    border: 0;
    overflow: initial;
    .rc-tabs-ink-bar {
      display: none !important;
    }
    .rc-tabs-bar {
      border: 0;
      width: 150%;
      @media (max-width: 1220px) {
        width: 105%;
      }
      @media (max-width: 1024px) {
        margin-left: 0%;
      }
    }
    .rc-tabs-nav-container {
      margin-bottom: 80px;
      @media (max-width: 1024px) {
        margin-bottom: 0%;
      }
      &:not(.rc-tabs-nav-container-scrolling) {
        .rc-tabs-nav-scroll {
          width: 100%;
          text-align: center;
          .rc-tabs-nav {
            float: none;
            display: block;
            .rc-tabs-tab {
              display: inline-block;
              float: none;
              @media (max-width: 425px) {
                display: none;
              }
            }
          }
        }
      }
      .rc-tabs-tab {
        font-size: 18px;
        color: #dcf4e8;
        font-weight: 400;
        min-width: 30%;
        text-align: center;
        margin-right: 0px;
        transition: 0.25s ease-in-out;

        &:hover {
          color: #fff;
        }
        &:last-child {
          margin-right: 0;
        }
        &:before,
        &:after {
          content: "";
          position: absolute;
          width: 100%;
          height: 6px;
          bottom: 0;
          left: 0;
          display: block;
          // @media (max-width: 767px) {
          //   display: none;
          // }
        }
        &:before {
          background: rgba(0, 0, 0, 0.08);
        }
        &:after {
          background: #fff;
          transform: scaleX(0);
          transform-origin: right center 0;
          transition: transform 0.7s cubic-bezier(0.19, 1, 0.22, 1) 0s;
        }
        &.rc-tabs-tab-active {
          color: #fff;
          &:after {
            background: #98e044;
            transform: scaleX(1);
            transform-origin: left center 0;
            transition: transform 0.35s cubic-bezier(0.43, 0.49, 0.51, 0.68);
          }
        }
        > div {
          margin-right: 8px;
        }
        @media (max-width: 1199px) {
          font-size: 16px;
          padding: 0 0 20px 0;
          min-width: 170px;
        }
        @media (max-width: 990px) {
          min-width: auto;
          padding: 0 10px 15px 10px;
        }
        @media (max-width: 767px) {
          font-size: 14px;
          padding: 0;
          svg {
            width: 20px;
          }
        }
      }
    }
    .rc-tabs-content {
      width: 166%;
      @media (max-width: 1024px) {
        margin-left: 0%;
        width: 97%;
      }
      @media (max-width: 425px) {
        margin-left: 3.5%;
        width: 100%;
      }
      @media (max-width: 375px) {
        margin-left: -2%;
        width: 100%;
      }

      .rc-tabs-tabpane {
        overflow: hidden;
        &.rc-tabs-tabpane-active {
          animation: 0.7s ScaleInUp;
        }
      }
    }
  .rc-tabs-tab-prev-icon,
  .rc-tabs-tab-next-icon {
    font-size: 20px;
    color: #fff;
    line-height: 1;
    display: block;
  }
`

const TextWrapper = styled.div`
  max-width: 540px;
  position: relative;
  padding-left: 12px;
  margin-right: 30px;
  margin: 0 10%;
  p {
    text-indent: 27px;
    margin-bottom: 25px;
  }

  i {
    color: rgba(52, 61, 72, 0.2);
    font-size: 20px;
    position: absolute;
    top: 0;
    left: 12px;
    z-index: -1;
  }

  @media (max-width: 1440px) {
    text-align: center;
    width: 84%;
  }
  @media (max-width: 1200px) {
    margin-top: -5%;
  }
  @media (max-width: 1024px) {
    margin-top: 0%;
  }
  @media (max-width: 990px) {
    margin-top: 0%;
  }
  @media (max-width: 768px) {
    margin-top: -7%;
  }
  @media (max-width: 425px) {
    margin-top: -7%;
  }
  .textSizes {
    @media (max-width: 425px) {
      font-size: 12px !important;
      text-align: justify !important;
    }
  }
  .text-m-bold {
    text-indent: 0;
    text-align: left;
    font-weight: bold;

    @media (max-width: 425px) {
      display: flex;
      width: 70%;
      font-weight: bold;
      margin-left: -4%;
      margin-bottom: 0;
    }
    @media (max-width: 375px) {
      display: flex;
      width: 70%;
      font-weight: bold;
      margin-left: -4%;
      text-indent: 0;
      margin-bottom: 0;
    }
    @media (max-width: 320px) {
      display: flex;
      width: 65%;
      justify-content: left;
      text-align: left;
      font-weight: bold;
      margin-left: -4%;
      text-indent: 0;
      margin-bottom: 0;
      line-height: "20px" !important;
    }
  }
  .text-ex-bold {
    text-indent: 0;
    text-align: left;
    font-weight: bold;


    @media (max-width: 425px) {
      display: flex;
      width: 59%;
      font-weight: bold;
      margin-left: -4%;
      margin-bottom: 0;
      text-align: left;
      text-indent: 0;
    }
  }
`
const SectionImage = styled.div`
  position: absolute;
  width: 200%;
  height: 40%;
  top: 20%;
  right: 50%;
  display: flex;
  align-items: center;

  @media (max-width: 767px) {
    width: 100%;
    position: relative;
    height: auto;
    top: 20%;
    left: auto;
  }

  img {
    max-width: 100%;
    height: auto;

    @media (max-width: 1220px) {
      display: none;
    }
  }
  .png {
    position: relative;
    top: 20%;
  }
  .objectWrapper {
    margin-right: auto;
    position: relative;
    .dashboardWrapper {
      position: absolute;
      top: 4vw;
      left: 0;
    }
  }
`
export { SectionWrapper, TextWrapper, SectionImage }
