import React from "react"
import PropTypes from "prop-types"
import Fade from "react-reveal/Fade"
import Box from "../../../components/Box"
import Button from "../../../components/Button"
import Image from "../../../components/Image"
import CardImage from "../../../assets/images/about/Group.png"
import Btn from "../../../assets/images/about/Btn.png"
import { SectionWrapper } from "./style"

import Text from "../../../components/Text"

const Digital = ({ sectionTitle, sectionHeader, button, description }) => {
  return (
    <SectionWrapper>
      <div className="fex-width">
        <Box>
          <Fade bottom cascade>
            <Box>
              <a href="https://web.agrabiz.de/#/register/step/1">
                <Button {...button} title="Starten Sie jetzt kostenlos" />
              </a>
            </Box>

            <Text
              {...description}
              content="Jetzt kostenlos und unverbindlich unseren Service testen."
              className="flex-text1"
            />
          </Fade>
        </Box>
      </div>
      <div className="fex-width hideShow">
        <Fade>
          <Box {...sectionHeader} className="flex-box">
            <Text
              {...sectionTitle}
              className="flex-text2"
              content="Zugriff auf Ihren aktuellen Bestand."
            />
            <Text
              {...sectionTitle}
              className="flex-text3"
              content="Sofort und automatisch."
            />
            <Image className="imgSize" src={CardImage} alt="Card Image" />
          </Box>
        </Fade>
      </div>
    </SectionWrapper>
  )
}

Digital.propTypes = {
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  button: PropTypes.object,
  imageArea: PropTypes.object,
  imageOne: PropTypes.object,
  description: PropTypes.object,
  sectionHeader: PropTypes.object,
  sectionTitle: PropTypes.object,
}

Digital.defaultProps = {
  sectionWrapper: {
    as: "section",
    pt: ["30px", "50px", "50px", "15%"],
    pb: ["40px", "40px", "40px", "80px"],
    ml: "-35%",
    mr: "-15%",
  },
  row: {
    flexBox: true,
    flexWrap: "wrap",
    ml: ["10%", "10%", "-12.5%", "5%", "10%", "10%"],
    mt: ["15%", "0%", "15%", "15%", "0%", "0%"],
    mb: ["-120%", "0%", "-75%", "-75%", "0%", "0%"],

    mr: "10%",
    alignItems: "center",
  },
  imageAreaRow: {
    flexDirection: "row-reverse",
  },
  col: {
    ml: ["28%", "28%", "28%", "28%", "21%", "28%"],
    pr: "15px",
    pl: "15px",
    alignItems: "center",
  },

  imageArea: {
    width: ["0%", "0%", "0%", "0%", "40%", "50%"],
  },
  button: {
    width: ["246px", "590px", "590px", "590px", "590px", "590px"],
    height: "125px",
    type: "button",
    fontSize: ["25px", "30px", "30px", "30px", "30px", "30px"],
    fontWeight: "800",
    color: "white",
    borderRadius: "10px",
    colors: "primaryWithBg",
    backgroundImage: { Btn },
    boxShadow: "0.7px 13px 27px 0 rgba(194, 207, 224, 0.73);",
  },
  imageOne: {
    width: "586px",
    height: "655px",
    ml: ["16%", "16%", "16%", "16%", "29%", "29%"],
    mr: "auto",
  },
  description: {
    fontSize: ["12px", "16px", "16px", "16px", "16px", "16px"],
    color: "gray",
    lineHeight: "1.75",
    mt: "5%",
    fontWeight: "350",
  },
  sectionHeader: {
    display: "flex",
    mb: ["0", "0", "30px", "56px"],
    ml: "0%",
    width: "150%",
  },
  sectionTitle: {
    width: "679px",
    height: "109px",
    fontSize: ["13px", "17px", "21px", "22px", "22px", "26px"],
    color: "#b6b6b7",
    lineHeight: "1.69",
    mb: ["-10%", "0"],
    textAlign: "center",
    fontWeight: "350",
  },
}

export default Digital
