import React from "react"
import PropTypes from "prop-types"
import Fade from "react-reveal/Fade"
import Box from "../../../components/Box"
import Text from "../../../components/Text"
import Heading from "../../../components/Heading"
import Button from "../../../components/Button"

import { useStaticQuery, graphql } from "gatsby"
import Image from "../../../components/Image"
import Container from "../../../components/Container"
import GlideCarousel from "../../../components/GlideCarousel"
import GlideSlide from "../../../components/GlideCarousel/glideSlide"
//import fpartner from '../../../assets/images/about/registration.png';
import SliderWrapper, { SliderBox } from "./style"
import { TestimonialItem } from "../../Registration/Slider/style"

import { TestimonialMeta, AuthorInfo } from "../../Landing/Banner/style"
const PaymentSection = ({
  sectionWrapper,
  row,
  col,
  col1,
  title,
  description,
  subTitle,
  textArea,
  imageArea,
  imageOne,
  button,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      dataJson {
        ABOUT_US {
          designation
          id
          image {
            publicURL
          }
          name
          title
        }
      }
    }
  `)

  const carouselOptions = {
    type: "carousel",
    autoplay: 6000,
    perView: 1,
    gap: 30,
    animationDuration: 800,
    breakpoints: {
      990: {
        perView: 1,
      },
    },
  }

  return (
    <SliderBox>
      <Box {...sectionWrapper}>
        <Container>
          <Box {...row} width="true">
            <Box {...col} {...textArea}>
              <Fade left>
                <SliderWrapper>
                  <GlideCarousel
                    options={carouselOptions}
                    carouselSelector="testimonial__slider"
                    controls={false}
                    bullets={true}
                    numberOfBullets={Data.dataJson.ABOUT_US.length}
                  >
                    <>
                      {Data.dataJson.ABOUT_US.map((item, index) => (
                        <GlideSlide key={`testimonial-slide-${index}`}>
                          <Box {...col1}>
                            <TestimonialItem>
                              <Text as="h1" content={item.title} {...title} />
                              <TestimonialMeta>
                                <AuthorInfo>
                                  <Box>
                                    <Heading
                                      as="h2"
                                      content={item.name}
                                      {...subTitle}
                                    />
                                    <Heading
                                      as="h3"
                                      content={item.designation}
                                      {...description}
                                    />
                                    <a href="https://web.agrabiz.de/#/register/step/1">
                                      <Button
                                      className='btn-register'
                                        {...button}
                                        title="Starten Sie jetzt kostenlos"
                                      />
                                    </a>
                                  </Box>
                                </AuthorInfo>

                                <Box></Box>
                              </TestimonialMeta>
                            </TestimonialItem>
                          </Box>

                          <Box
                            {...row}
                            {...imageArea}
                            className="flex-img-slider"
                          >
                            <Fade right>
                              <Image
                                {...imageOne}
                                src={item.image.publicURL}
                                alt="Card Image"
                                // className="imgSlider"
                              />
                            </Fade>
                          </Box>
                        </GlideSlide>
                      ))}
                    </>
                  </GlideCarousel>
                </SliderWrapper>
              </Fade>
            </Box>
          </Box>
        </Container>
      </Box>
    </SliderBox>
  )
}

PaymentSection.propTypes = {
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  title: PropTypes.object,
  subTitle: PropTypes.object,
  description: PropTypes.object,
  textArea: PropTypes.object,
  imageArea: PropTypes.object,
  imageOne: PropTypes.object,
}

PaymentSection.defaultProps = {
  sectionWrapper: {
    as: "section",
    pt: ["60px", "60px", "60px", "60px"],
    pb: ["140px", "40px", "40px", "115px"],
  },
  row: {
    flexBox: true,
    flexWrap: "wrap",
    ml: "-15px",
    mr: "-15px",
    pb: "0px",
    alignItems: "center",
  },
  imageAreaRow: {
    flexDirection: "row-reverse",
  },
  col: {
    pr: "15px",
    pl: "15px",
  },
  textArea: {
    width: ["80%", "80%", "55%", "50%", "42%"],
    mb: ["40px", "40px", "0", "0", "0"],
  },
  imageArea: {
    width: ["0%", "0%", "30%", "43%", "43%", "43%"],
    mt: "9%",
    ml: ["8%", "8%", "44%", "43%", "50%", "30%"],
  },
  title: {
    fontSize: ["18px", "18px", "18px", "27px", "36px", "36px"],
    fontWeight: "300",
    color: "headingColor",
    letterSpacing: "-0.025em",
    mb: "20px",
  },
  subTitle: {
    fontSize: ["26px", "26px", "26px", "32px", "46px", "46px"],
    fontWeight: "700",
    color: " #98e044",
    letterSpacing: "-0.025em",
    mb: "20px",
  },
  description: {
    fontSize: ["14px", "15.5px", "17.5px", "18.5", "18.5px", "20.5px"],
    fontWeight: "350",
    color: "headingColor",
    lineHeight: "1.75",
    textAlign: "justify",
    marginTop: "10%",
    marginBottom: "10%",
  },

  imageOne: {
    mb: "40px",
    ml: "auto",
    mr: "auto",
    mt: "-75px",
  },

  col1: {
    width: ["50%", "0%", "35%", "35%", "37%"],
    mb: ["0%", "0%", "0%", "0%", "-12%", "0"],
    mt: ["11.5%"],
    ml: "4%",
    height: ["300px", "100px", "206px", "100px", "100px", "100px"],
  },
  button: {
    width: ["300px", "300px", "300px", "400px", "550px", "550px"],
    height: ["70px","100px","100px","100px","105px","105px"],
    type: "button",
    fontSize: ["20px", "25px", "25px", "30px", "30px", "30px"],
    fontWeight: "800",
    color: "white",
    borderRadius: "10px",
    colors: "primaryWithBg",
    boxShadow: "0.7px 13px 27px 0 rgba(194, 207, 224, 0.73);",
  },
}

export default PaymentSection
